import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`SVG-Bilder sind ein integraler Bestandteil des modernen Webdesigns. SVG, kurz
für `}<ItalicText mdxType="ItalicText">{`Scalable Vector Graphics`}</ItalicText>{`, sind hoch skalierbar und haben eine geringe Dateigröße.
Ihr könnt sie sogar mit CSS stylen, wie ich euch zeigen werde.`}</p>
    <p>{`Ein SVG-Bild kann mit dem `}<InlineCode mdxType="InlineCode">{`<`}{`img`}{`>`}</InlineCode>{`-Tag oder der
CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`background-image`}</InlineCode>{` in eine Website eingebettet werden. In diesem Fall könnt ihr
aber das Styling des Bildes nicht ändern. Ich verwende lieber Inline-SVG-Bilder. Diese können mit CSS angepasst und
sogar animiert werden.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB5lNbFtSP/8QAGRAAAgMBAAAAAAAAAAAAAAAAARIAAhEh/9oACAEBAAEFAk4iTBMJvhN0An//xAAWEQEBAQAAAAAAAAAAAAAAAAAAIQH/2gAIAQMBAT8BmK//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFH/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECIRAREjFB/9oACAEBAAY/AnH0hHksOy9Uds//xAAaEAACAwEBAAAAAAAAAAAAAAABIQARQTEQ/9oACAEBAAE/IQRXxGAZ0VniKMaNQ7Qlzkh//9oADAMBAAIAAwAAABDML//EABYRAQEBAAAAAAAAAAAAAAAAABEAAf/aAAgBAwEBPxDAJn//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QdWxf/8QAHhABAAICAQUAAAAAAAAAAAAAAREhAEFxMVFhgaH/2gAIAQEAAT8QPTTB1zxi3G9KpQWy4hpwWOS5Ke9ej7gqECqSp0Yoteaz/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Frau malt eine Katzenfigur auf ein Stück Papier.",
          "title": "Eine Frau malt eine Katzenfigur auf ein Stück Papier.",
          "src": "/static/1513877f07e4e3069f3b3e975b2aef6d/e5166/pexels-andrea-piacquadio-painter.jpg",
          "srcSet": ["/static/1513877f07e4e3069f3b3e975b2aef6d/f93b5/pexels-andrea-piacquadio-painter.jpg 300w", "/static/1513877f07e4e3069f3b3e975b2aef6d/b4294/pexels-andrea-piacquadio-painter.jpg 600w", "/static/1513877f07e4e3069f3b3e975b2aef6d/e5166/pexels-andrea-piacquadio-painter.jpg 1200w", "/static/1513877f07e4e3069f3b3e975b2aef6d/b17f8/pexels-andrea-piacquadio-painter.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Andrea Piacquadio / pexels.com`}</em></p>
    <p>{`Wie kann man Inline-SVG-Bilder einbetten? Was sind die besten Methoden, um sie mit CSS-Eigenschaften und Variablen anzupassen?
Lest weiter, um die Antworten darauf zu erfahren.`}</p>
    <h2>{`Wie ihr ein Inline-SVG einbettet`}</h2>
    <p>{`SVG-Bilder können mit dem `}<InlineCode mdxType="InlineCode">{`<`}{`svg`}{`>`}</InlineCode>{`-Tag direkt in ein HTML-Dokument eingebettet werden. Öffnet
einfach die Bilddatei in einem Code-Editor, kopiert den Code und fügt ihn in euer HTML-Template ein. Hier ist ein Beispiel:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<svg height="48" width="48" viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
    <path d="m393-165 279-335H492l36-286-253 366h154l-36 255Zm-73 85 40-280H160l360-520h80l-40 320h240L400-80h-80Zm153-395Z"></path>
</svg>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Websites werden oft mithilfe komponentenbasierter JavaScript-Frameworks wie `}<a parentName="p" {...{
        "href": "https://angular.io/"
      }}>{`Angular`}</a>{` oder
`}<a parentName="p" {...{
        "href": "https://react.dev/"
      }}>{`React`}</a>{` erstellt. Damit könnt ihr ein Inline-SVG in einer eigenen Komponente verpacken, die ihr dann
an verschiedenen Stellen eurer Website wiederverwenden könnt. Ein Beispiel dafür ist die
`}<a parentName="p" {...{
        "href": "https://mui.com/material-ui/material-icons/"
      }}>{`Material UI Icon-Bibliothek`}</a>{`.`}</p>
    <p>{`Die UI-Bibliothek `}<a parentName="p" {...{
        "href": "https://material.angular.io/components/icon/overview#svg-icons"
      }}>{`Angular Material`}</a>{` geht sogar noch einen
Schritt weiter: Damit könnt ihr SVG-Dateien als Assets der Website hinzufügen. Die Anwendung lädt die Bilder später vom Server,
sodass der SVG-Code über die `}<InlineCode mdxType="InlineCode">{`<`}{`mat-icon`}{`>`}</InlineCode>{`-Komponente in das HTML-Dokument eingebettet werden kann.`}</p>
    <h2>{`Demo: Flexibles Styling von SVG-Icon-Buttons`}</h2>
    <p>{`Ich habe eine `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/fancy-css-playground/#/inlinesvg"
      }}>{`Demo`}</a>{` mit Anwendungsfällen für Inline-SVGs
erstellt. Ihr könnt die Farbe der Icons über ein Farbwahlelement anpassen:`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/inlinesvg" title="Demo: Flexibles Styling von Inline-SVG-Bildern" loading="lazy"></iframe>
    <p>{`Wie das funktioniert? Indem ihr den `}<InlineCode mdxType="InlineCode">{`fill`}</InlineCode>{` des SVG-Bildes mit der
CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`color`}</InlineCode>{` verknüpft.`}</p>
    <h2>{`SVG-Bilder mit CSS stylen`}</h2>
    <p>{`Als erstes setzt ihr die Eigenschaft `}<InlineCode mdxType="InlineCode">{`fill`}</InlineCode>{` des SVG-Bildes auf den Wert `}<InlineCode mdxType="InlineCode">{`currentColor`}</InlineCode>{`.
Dieses spezielle Schlüsselwort steht für den Wert der `}<InlineCode mdxType="InlineCode">{`color`}</InlineCode>{` CSS-Eigenschaft eines Elements. Auf diese
Weise werden die Vektoren des SVG-Bildes mit der Schriftfarbe des Elements gefüllt. Ihr könnt die Eigenschaft im SVG-Code setzen:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<svg fill="currentColor" height="48" width="48" viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
    <path d="..."></path>
</svg>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Oder ihr definiert die Eigenschaft im CSS-Code:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.icon svg {
    fill: currentColor;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Jetzt könnt ihr das Aussehen des SVG-Bildes über die CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`color`}</InlineCode>{` steuern. Das erleichtert
die Verwaltung von Farbthemen und verschiedenen Zuständen von Steuerelementen.`}</p>
    <p>{`Sagen wir, ihr verwendet eine Schaltfläche mit Icon und Text, wie in meiner Demo oben: Ihr könnt alle Elemente innerhalb der
Schaltfläche mit einer einzigen CSS-Regel einfärben. Verschiedene Zustände wie `}<InlineCode mdxType="InlineCode">{`:hover`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`:focus`}</InlineCode>{`
oder `}<InlineCode mdxType="InlineCode">{`:disabled`}</InlineCode>{` sind einfach zu gestalten. Ihr könnt sogar den Farbwechsel animieren:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.primary-btn {
    color: #f118a6;
    transition: color 0.5s ease;
}
.primary-btn:hover, .primary-btn:focus {
    color: #920c64;
    outline: 2px solid currentColor;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`CSS-Variablen für mehrfarbige Bilder verwenden`}</h2>
    <p>{`Ihr könnt sogar `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties"
      }}>{`CSS-Variablen`}</a>{` innerhalb von
SVG-Code verwenden. Das kann nützlich sein, wenn euer SVG-Bild mehrere Farben enthält. In diesem Fall könnt ihr die Hauptfarbe
weiterhin mit der CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`color`}</InlineCode>{` verknüpfen. Für die anderen Farben solltet ihr eigene
CSS-Variablen definieren:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="..." />
    <path d="..." />
    <path fill="var(--secondary-fill)" d="..." />
</svg>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Im Beispiel oben werden das erste und zweite `}<InlineCode mdxType="InlineCode">{`path`}</InlineCode>{`-Element durch die definierte Schriftfarbe eingefärbt.
Die Füllung des dritten Pfades könnt ihr über die CSS-Variable `}<InlineCode mdxType="InlineCode">{`--secondary-fill`}</InlineCode>{` steuern:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.icon {
    color: darkred;
    --secondary-fill: white;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Der Artikel `}<a parentName="p" {...{
        "href": "https://frontstuff.io/multi-colored-svg-symbol-icons-with-css-variables"
      }}><span lang="en">{`„Multi-Colored SVG Symbol Icons with CSS Variables“`}</span></a>{`
gibt euch einen ausführlichen Einblick in das Konzept.`}</p>
    <h2>{`Macht eure Bilder barrierefrei`}</h2>
    <p>{`Und nicht zuletzt: Vergesst nicht auf die Barrierefreiheit! Wenn das SVG-Bild rein dekorativ ist, dann
verwendet `}<InlineCode mdxType="InlineCode">{`aria-hidden="true"`}</InlineCode>{`, um das Element vor assistiven Technologien zu verbergen.`}</p>
    <p>{`Wenn das Bild wichtige Informationen vermittelt, solltet ihr `}<InlineCode mdxType="InlineCode">{`role="img"`}</InlineCode>{` setzen und mit
dem Attribut `}<InlineCode mdxType="InlineCode">{`aria-label`}</InlineCode>{` einen alternativen Text angeben. Lest den Artikel
`}<a parentName="p" {...{
        "href": "https://www.unimelb.edu.au/accessibility/techniques/accessible-svgs"
      }}><span lang="en">{`„Accessible SVGs“`}</span></a>{` für
weitere Informationen und Anwendungsfälle.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      